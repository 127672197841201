import request from '@/../utils/request'
const api_name = '/iotechserver/jurissmallsystem'

export default {
    // 查询权限类型管理
    getJurisSmallSystem() {
        return request({
            url: `${api_name}/getJurisSmallSystem`,
            method: 'get',
        })
    },

    // 添加权限
    addJurisSmallSystem(smallSystemName) {
        return request({
            url: `${api_name}/addJurisSmallSystem`,
            method: 'post',
            data: { smallSystemName: smallSystemName },
        })
    },

    // 删除权限类型
    deleteJurisSmallSystem(id) {
        return request({
            url: `${api_name}/deleteJurisSmallSystem/${id}`,
            method: 'delete',
        })
    },



}