import request from '@/../utils/request'
const api_name = '/iotechserver/jurisbigsystem'

export default {

    // 查询权限系统名称
    getJurisBigSystemById() {
        return request({
            url: `${api_name}/getJurisBigSystemById`,
            method: 'get',
        })
    },

    // 添加 权限类型
    addBigSystem(bigSystemName) {
        return request({
            url: `${api_name}/addBigSystem`,
            method: 'post',
            data: { bigSystemName: bigSystemName },
        })
    },

    // 查询权限系统名称 对应 的权限类型
    getBigSmallSystemInfo(bigSystemId) {
        return request({
            url: `${api_name}/getBigSmallSystemInfo/${bigSystemId}`,
            method: 'get'
        })
    },

    // 删除权限系统
    deleteJurisBigSystem(id) {
        return request({
            url: `${api_name}/deleteJurisBigSystem/${id}`,
            method: 'delete',
        })
    },



}