import request from '@/../utils/request'
const api_name = '/iotechserver/loginUserJuris'

export default {
  //添加账号绑定的行业
  saveAccountIndustry(name, industryList, value) {
    return request({
      url: `${api_name}/saveAccountIndustry`,
      method: 'post',
      data: {
        name: name,
        industryList: industryList,
        value: value,
      }
    })
  },
  //添加公司绑定行业
  editDepartmentIndustry(ciId, industryList) {
    return request({
      url: `${api_name}/editDepartmentIndustry`,
      method: 'post',
      data: {
        name: ciId,
        industryList: industryList
      }
    })
  },

  getIndustrysByName(type, name) {
    return request({
      url: `${api_name}/getIndustrysByName/${type}/${name}`,
      method: 'get',
    })
  },

  // 根据userName和menuName查权限
  getMenuJurisByMenuName(userName, menuName) {
    return request({
      url: `${api_name}/getIndustrysByName/${userName}/${menuName}`,
      method: 'get',
    })
  }



}