import request from '@/../utils/request'
const api_name = '/iotechserver/jurisbigsmallsystem'


export default {

    // 获取大小系统关系
    getJurisBigSmallSystem() {
        return request({
            url: `${api_name}/getJurisBigSmallSystem`,
            method: 'get',
        })
    },

    // 根据大系统查小系统
    getSystemInfo(id) {
        return request({
            url: `${api_name}/getSystemInfo/${id}`,
            method: 'get',
        })
    },

    // 根据大系统，重新编辑小系统

    updateJurisBigSmallSystem(bigSystemId, smallSystemIds) {
        return request({
            url: `${api_name}/updateJurisBigSmallSystem`,
            method: 'post',
            data: {
                bigSystemId: bigSystemId,
                smallSystemIds: smallSystemIds
            }
        })
    },



    // 删除大小系统关系
    deleteJurisBigSmallSystem(bigSystemId, smallSystemId) {
        return request({
            url: `${api_name}/deleteJurisBigSmallSystem/${bigSystemId}/${smallSystemId}`,
            method: 'delete',
        })
    },

    /**
     * ********** 公司系统相关 **********
     */

    // 通过传入公司id  获取人员和大小系统的id和名称
    getJurisFromCompanyId(id) {
        return request({
            url: `${api_name}/getJurisFromCompanyId/${id}`,
            method: 'get',
        })
    }

}